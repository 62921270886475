<template>
  <g class="montañas">
    <path
      class="cls-1"
      d="M1117.04,862.09h112.44c-9.69-10.86-20.99-4.14-29.89-7.71,29.88-5.11,60.24,2.32,92.94-2.23-11.53-11.28-25.6-1.53-36.18-10.4,22.42,3.03,43.69,6.54,62.89-11.71-31.62-4.82-61.78,2.39-95.55,.52,6.73-1.92,10-3.15,13.38-3.73,3.51-.6,7.12-.78,10.69-.8,17.91-.11,35.83,.07,53.73-.2,17.28-.26,34.55-.95,52.37-1.47v4.13c-12.68,3.22-24.77,6.29-38.92,9.88,3.07,3.37,4.55,4.99,6.24,6.84-.77,1.96-1.84,4.68-3.72,9.44-3.73,2.05-9.6,5.28-15.45,8.49-8.79,1.26-17.74,2.54-30.15,4.32-4.97-1.73-13.65-5.47-24.32,4.02h47.05c0,.42-.01,.84-.02,1.26-10.39,1.85-20.78,3.7-31.62,5.63,11.2,7.93,23.1,.95,35.4,3.15-17,7.52-35.32,.9-52.25,8.34-4.55-.81-10.37-2.68-16.2-2.73-31.22-.24-62.44-.23-93.65,.14-9.68,.11-19.33,1.75-29.02,2.38-5.35,.35-10.75,.06-16.13,.06-.04-1-.08-2.01-.12-3.01,7.94-.79,15.88-2.16,23.83-2.29,38.38-.61,76.76-.84,115.14-1.32,6.47-.08,12.94-.75,19.41-1.14,.05-1.19,.1-2.38,.15-3.56-10.1-1.08-20.19-3.08-30.29-3.07-32.72,.03-65.44,.9-98.16,1.21-15.05,.14-30.3,1.93-47.97-3.94,57.47-3.8,112.12,2.71,166.45-3.69,.15-.02,.19-.95,.61-3.15h-123.62c.17-1.22,.33-2.43,.5-3.65Z"
    ></path>
    <path
      class="cls-1"
      d="M434.54,794.88c10.55-3.15,21.13-6.2,31.64-9.49,12.57-3.93,25.05-8.1,37.6-12.09,4.37-1.39,8.75-2.98,13.24-3.71,36.27-5.87,72.46-12.36,108.9-16.95,17.02-2.15,32.88-7.21,48.65-12.92,31.55-11.42,63.77-18.1,97.39-15.35,8.3,.68,16.39,3.9,24.57,5.96l.25-.32c-7.63-.43-15.38-.12-22.85-1.42-20.03-3.5-39.35-2.15-58.71,4.44-22.64,7.71-45.42,15.31-68.63,20.95-16.2,3.93-33.29,4.07-49.86,6.65-26.66,4.15-53.69,7.15-79.69,13.95-22.08,5.77-43.57,13.96-66.19,17.89-5.48,.95-11.05,1.41-16.59,2.09l.26,.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1390.04,794.6c8.35,1.93,16.63,4.29,25.07,5.72,22.16,3.77,44.15,4.93,66.75,1.14,12.1-2.03,25.2,1.51,37.81,2.9,10.01,1.1,19.96,2.74,29.81,5.6-16.15-1.21-32.28-2.71-48.45-3.5-8.51-.42-17.33-.81-25.56,.92-17.99,3.8-35.57,1.38-53.14-1.59-11.36-1.93-22.5-5.13-33.73-7.76,.48-1.14,.96-2.28,1.44-3.42Z"
    ></path>
    <path
      class="cls-1"
      d="M1242.38,785.43c7.43,2.65,14.67,1.76,22.33,.17,15.94-3.3,32.11-5.49,50.3-8.49-9.01-2.16-16.29-3.9-23.57-5.64,.04-.48,.08-.96,.12-1.44,6.22,.6,12.44,1.26,18.67,1.8,10.57,.92,21.18,1.5,31.71,2.77,4.23,.51,8.29,2.46,12.19,4.6-19.26-1.47-37.7,3.74-56.44,4.96-19.43,1.27-38.43,3.92-55.04,13.16v-12.23l-.26,.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1017.7,778.97c-8.35,3.11-16.5,7-25.09,9.14-12.24,3.04-24.8,4.79-36.75,7.01-7.99-11.21-22.02-9.65-30.06-19.59,7.4,2.78,14.85,5.45,22.2,8.36,10.64,4.21,21.08,5.97,32.67,2.68,12.14-3.45,24.84-4.93,37.3-7.26,0,0-.27-.33-.27-.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1242.64,785.1c-19.77,2.97-38.19-4.83-57.28-7.23-7.94-1-15.75-3.05-23.28-6.72,14.58,2.03,29.19,3.93,43.74,6.13,11.01,1.67,21.97,3.65,32.92,5.67,1.32,.24,2.43,1.62,3.63,2.47l.26-.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1733,811.54c-17.59,10.42-34.09-.23-50.36,.46,16.29,.16,32.42-7.51,50.36-.46Z"
    ></path>
    <path
      class="cls-1"
      d="M1146.85,742.17c-18.3,10.19-38.53,13.79-58.83,17.16,19.5-6.26,37.97-16.12,59.07-16.86l-.24-.3Z"
    ></path>
    <path
      class="cls-1"
      d="M320.11,804.37c9.02,0,18.44-1.64,26.96,.43,9.83,2.39,18.87,2.07,28.48,.21,6.28-1.21,12.86-.9,19.33-.19-16.81,3.41-33.33,7.81-50.83,2.63-7.38-2.18-15.94-.36-23.97-.36,0-.91,0-1.82,.01-2.73Z"
    ></path>
    <path
      class="cls-1"
      d="M1255.46,762.35c-20.03-.22-38.09-8.07-56.54-14.91,9.31,1.67,18.77,2.76,27.89,5.18,9.72,2.58,19.11,6.43,28.65,9.72Z"
    ></path>
    <path
      class="cls-1"
      d="M866.72,766.06h-79.02c0-.41,0-.83,0-1.24h79.17c-.05,.41-.1,.83-.16,1.24Z"
    ></path>
    <path
      class="cls-1"
      d="M879.67,799.08c21.6-1.21,43.2-2.41,68.24-3.81-24.81,8.48-46.92,3.95-68.24,3.81Z"
    ></path>
    <path
      class="cls-1"
      d="M870.57,754.55c-17.18-1.63-34.36-3.26-51.06-4.84,8.3-1.01,17.18-2.1,26.07-3.18,.1-1.05,.19-2.1,.29-3.15,8.14,3.83,16.28,7.66,24.42,11.5l.28-.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1118.34,832.18c16.8-5.51,33.6-11.01,50.4-16.52,.31,.95,.61,1.91,.92,2.86-16.59,6.15-33.07,12.65-51.32,13.66Z"
    ></path>
    <path
      class="cls-1"
      d="M1147.08,742.47c9.9-6.87,20.18-8.36,31.27-2.77,3.92,1.98,8.26,3.13,14.5,5.43-3.32,1.21-5.46,2.73-6.34,2.19-12.51-7.65-26.09-6.28-39.67-5.15l.24,.3Z"
    ></path>
    <path
      class="cls-1"
      d="M1183.87,811.25c13.65-2.41,27.3-4.82,40.95-7.22-12.58,9.31-26.81,8.77-40.95,7.22Z"
    ></path>
    <path
      class="cls-1"
      d="M870.29,754.88c13.9,1,25.65,7.84,37.67,13.9-13.2-2.81-26.45-5.5-37.4-14.22l-.28,.33Z"
    ></path>
    <path
      class="cls-1"
      d="M796.55,730.33c9.49-1.44,17.52,2.76,25.59,6.75-9.4,1.13-17.62-2.07-25.34-7.07l-.25,.32Z"
    ></path>
    <path
      class="cls-1"
      d="M1638.98,819.47c9.56-5.19,19.71-6.96,30.6-4.43-10.29,1.37-20.57,2.74-30.86,4.1l.26,.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1680.62,824.9c-6.85,2.4-13.7,4.8-20.55,7.2-.36-.98-.71-1.96-1.07-2.94,6.57-2.46,13.15-4.93,19.72-7.39,.63,1.05,1.27,2.09,1.9,3.14Z"
    ></path>
    <path
      class="cls-1"
      d="M1766.18,800.96c8.06-1.53,16.11-3.05,24.17-4.58,.18,.85,.36,1.69,.53,2.54-8.03,1.7-16.05,3.4-24.08,5.11-.21-1.02-.42-2.04-.63-3.06Z"
    ></path>
    <path
      class="cls-1"
      d="M858.34,779.28h26.99c-.07,1.04-.14,2.08-.21,3.12-8.84-1.15-17.68-2.29-26.52-3.44l-.26,.32Z"
    ></path>
    <path
      class="cls-1"
      d="M1638.72,819.14c-10.23,1.03-20.46,2.07-30.7,3.1l.28,.35c9.72-6.02,20.4-2.52,30.67-3.12l-.26-.33Z"
    ></path>
    <path
      class="cls-1"
      d="M858.59,778.96h-27.94c.06-.95,.13-1.89,.19-2.84,9.16,1.06,18.33,2.11,27.49,3.17l.26-.32Z"
    ></path>
    <path
      class="cls-1"
      d="M782.3,746.76h23.48c0,.38,0,.76-.01,1.13h-23.73c.09-.38,.17-.76,.26-1.13Z"
    ></path>
    <path
      class="cls-1"
      d="M1017.97,779.3c7.97-2.31,15.94-4.61,23.9-6.92l.7,3.31c-8.29,1.09-16.58,2.19-24.88,3.28,0,0,.27,.33,.27,.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1608.03,822.24c-8.96,4.96-18.41,2.51-27.79,.35h28.08l-.28-.35Z"
    ></path>
    <path
      class="cls-1"
      d="M434.28,794.55c-7.22,2.33-14.44,4.66-21.66,6.98-.27-1.25-.54-2.5-.81-3.76,7.58-.97,15.16-1.93,22.73-2.9l-.26-.33Z"
    ></path>
    <path
      class="cls-1"
      d="M1558.84,810.89h17.66c0,.34,0,.68,0,1.01h-17.67c0-.34,0-.68,0-1.01Z"
    ></path>
    <path
      class="cls-1"
      d="M1555.94,826.46c4.81-.22,9.62-.44,14.43-.66,.02,.46,.04,.92,.07,1.38h-14.44c-.02-.24-.04-.48-.06-.72Z"
    ></path>
    <path
      class="cls-1"
      d="M1983.78,805.69c-4.63-1.04-9.27-2.07-13.9-3.11l.41-2.05c4.66,.89,9.32,1.78,13.98,2.67-.16,.83-.33,1.66-.49,2.49Z"
    ></path>
  </g>
</template>
<script>
export default {
  name: "svg montañas",
};
</script>